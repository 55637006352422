import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';

import nation_wabanaki from '../images/remerciements/nation_wabanaki.png';
import les_loups_du_castelet from '../images/remerciements/les_loups_du_castelet.png';
import studio_equipment from '../images/remerciements/studio_equipment.png';
import isound from '../images/remerciements/i-sound.png';
import audio_tech from '../images/remerciements/audio_tech.png';
import novalab from '../images/remerciements/novalab.png';
import cible_music_international from '../images/remerciements/cible_music_international.png';

const RemerciementsPage = () => (
  <Layout>
    <SEO title="Remerciements" />
    <Helmet bodyAttributes={{ class: 'remerciements' }} />
    <div className="staticContent" id="staticContent_thanks">
      <h1>Remerciements</h1>
      <ul>
        <li className="group">
          <h2>Nation Ab&#233;nakise</h2>
          <p className="creditImage">
            <img
              alt="Nation Ab&#233;nakise"
              height="130"
              src={nation_wabanaki}
              width="130"
            />
          </p>
          <div className="creditText">
            <p>
              C&#8217;est avec &#233;motion que Leelo Pascal remercie{' '}
              <strong>Denys Sweda</strong>, Grand Chef de la Nation
              Ab&#233;nakise pour l&#8217;avoir &#233;paul&#233; et soutenu
              durant toutes ces ann&#233;es. Entre Leelo Pascal et le Grand
              Chef, r&#232;gnent Respect et Amiti&#233;.
            </p>
            <p>&#160;</p>
            <p>
              Au nom de la terre M&#232;re et des forces de la Nature, recevez,
              Amour et Paix.
            </p>
            <p>&#160;</p>
            <p>
              Denys Sweda vous invite &#224; d&#233;couvrir sa nation,
              l&#8217;histoire et la beaut&#233; de son peuple:
            </p>
            <p>&#160;</p>
            <p>
              <a href="http://www.nationwabanaki.com/">
                www.nationwabanaki.com
              </a>
            </p>
            <p>
              <a href="http://lebatondeparoles.skynetblogs.be/archive/2010/10/12/les-abenaquis.html">
                Les Ab&#233;naquis
              </a>
            </p>
          </div>
        </li>
        <li className="group">
          <h2>Les loups du castelet</h2>
          <p className="creditImage">
            <img alt="Les loups du castelet" src={les_loups_du_castelet} />
          </p>
          <div className="creditText">
            <p>Tournage de films, publicit&#233;s, photos, vid&#233;oclips</p>
            <p>&#160;</p>
            <p>
              Un grand merci &#224; <strong>Francky Estrade</strong>,
              Ma&#238;tre loup, et son &#233;quipe d&#8217;avoir partag&#233;
              savoir et sagesse avec Cible Music International.
            </p>
            <p>&#160;</p>
            <p>
              Leelo Pascal a eu la grande chance de partager des moments
              intenses et inoubliables avec les loups du Castelet lors du
              tournage de son vid&#233;oclip &#171;&#160;Mais que
              restera-t-il&#160;&#187;. Merci aussi &#224;{' '}
              <strong>Vincent Ablot</strong> pour les photos de Leelo Pascal
              avec les loups.
            </p>
            <p>&#160;</p>
            <p>
              Si vous aussi vous aimez les loups, visitez le site{' '}
              <a href="http://www.les-loups-du-castel.com/">
                www.les-loups-du-castel.com
              </a>{' '}
              et d&#233;couvrez les incroyables r&#233;f&#233;rences de leurs
              tournages. N&#8217;h&#233;sitez pas &#224; les contacter et
              visitez directement la louverie, afin de vous offrir une vraie
              rencontre avec ces animaux mystiques.
            </p>
          </div>
        </li>
        <li className="group">
          <h2>Studio Equipment</h2>
          <p className="creditImage">
            <img alt="Studio Equipment" src={studio_equipment} />
          </p>
          <div className="creditText">
            <p>
              Leelo Pascal remercie <strong>Terry Nelson</strong> pour son
              soutien et sa longue collaboration.
            </p>
            <p>&#160;</p>
            <p>
              Studio Equipment offers a consultation service for acoustics and
              systems for post-production and music studios, screening rooms and
              sound reinforcement. The company is also the Swiss distributor for
              a selection of prestigious names in the pro audio world, including
              acoustic panels. We are part of Technical Resources for IBC and
              involved with audio education at the CFMS, Lausanne.
            </p>
            <p>&#160;</p>
            <p>
              <a href="http://www.studioequipment.ch/">
                www.studioequipment.ch
              </a>
            </p>
          </div>
        </li>
        <li className="group">
          <h2>Photographies</h2>
          <p className="creditImage">
            <img alt="i-sound" src={isound} />
          </p>
          <div className="creditText">
            <p>
              Un tout grand Merci &#224; <strong>Fabrice Del-Prete</strong> qui
              a sign&#233; la plupart des photos du site internet de Leelo
              Pascal. Ce photographe professionnel et m&#233;lomane a su
              r&#233;v&#233;ler &#224; la perfection l&#8217;identit&#233; de
              l&#8217;artiste, tout en soulignant son style pop-rock.
            </p>
            <p>&#160;</p>
            <p>
              <a href="http://www.i-sound.ch/">www.i-sound.ch</a>
            </p>
          </div>
        </li>
        <li className="group">
          <h2>Audio Tech KST SA</h2>
          <p className="creditImage">
            <img alt="Audio Tech KST SA" src={audio_tech} />
          </p>
          <div className="creditText">
            <p>Un grand Merci &#224; Audio Tech.</p>
            <p>&#160;</p>
            <p>
              Audio Tech KST SA, une &#233;quipe&#160;passionn&#233;e par
              la&#160; technique de l&#8217;&#233;clairage et l&#8217;audio pro.
              Un conseil &#224; la client&#232;le, un service, distributeur
              moderne de produits professionnels, techniques
              audio-&#233;clairage et m&#233;diatiques offrant des marques
              &#233;tablies et reconnues mondialement.
            </p>
            <p>&#160;</p>
            <p>
              <a href="http://www.audiotech.ch/">www.audiotech.ch</a>
            </p>
          </div>
        </li>
        <li className="group">
          <h2>novalab</h2>
          <p className="creditImage">
            <img alt="novalab" src={novalab} />
          </p>
          <div className="creditText">
            <p>
              Tout d'une seule source: web design, programmation d'applications
              web, l'optimisation des moteurs de recherche (SEO).
            </p>
            <p>&#160;</p>
            <p>
              Un tout grand Merci &#224; <strong>Florian Moser</strong> pour le
              travail extraordinaire et la grande subtilit&#233; artistique dont
              il a fait preuve lors de la cr&#233;ation du site internet de
              Leelo Pascal.
            </p>
            <p>&#160;</p>
            <p>
              <a href="http://www.novalab.ch/">www.novalab.ch</a>
            </p>
          </div>
        </li>
        <li className="group">
          <h2>Maison au Qu&#233;bec</h2>
          <p>
            Amoureux de la nature et du Qu&#233;bec, pour vous offrir un
            s&#233;jour dans un havre de paix, contactez{' '}
            <a href="mailto:angeecurcio@videotron.ca">
              angeecurcio@videotron.ca
            </a>{' '}
            afin de louer une charmante maison au c&#339;ur des bois.
          </p>
        </li>
        <li className="group">
          <h2>Cible Music International</h2>
          <p className="creditImage">
            <img
              alt="Cible Music International"
              src={cible_music_international}
            />
          </p>
          <div className="creditText">
            <p>
              Cible Music International remercie tous ses collaborateurs et
              acteurs de l&#8217;album de Leelo Pascal pour leur
              professionnalisme et leur engagement.
            </p>
          </div>
        </li>
      </ul>
    </div>
  </Layout>
);

export default RemerciementsPage;
